import { lazy, Suspense } from 'react'
import { Tab } from 'semantic-ui-react'

import { useEditionStore } from '@modules/Edition/Presentation/store/EditionStore'
import useGetTabPanels from '@shared/Presentation/hooks/useGetTabPanels'

import AsFormProvider from '@shared/Presentation/editors/diarioas/AsFormProvider'
import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'
const CincoDiasFormProvider = lazy(() => import('@shared/Presentation/editors/cincodias/CincoDiasFormProvider'))
const ElPaisFormProvider = lazy(() => import('@shared/Presentation/editors/elpais/ElPaisFormProvider'))

const panes = [
  { 
    menuItem: 'Diario AS', render: () => (
      <Tab.Pane>
        <AsFormProvider />
      </Tab.Pane>
    )
  },
  { 
    menuItem: 'El País', render: () => (
      <Suspense fallback={<></>}>
        <Tab.Pane>
          <ElPaisFormProvider />
        </Tab.Pane>
      </Suspense>
    )
  }
]

const TabPanel = ({ ...rest }) => {
  const { resetEditions } = useEditionStore()
  const { resetTags } = useTagStore()
  const panels = useGetTabPanels(panes)

  const handleOnTabChange = () => {
    resetEditions()
    resetTags()
  }

  return (
    <>
      {
        panels === null
          ? <p>No tienes permisos para acceder a ningún panel. Por favor, contacta con el administrador.</p>
          : <Tab
            menu={{ 
              secondary: true,
              pointing: true 
            }}
            panes={panels}
            onTabChange={handleOnTabChange}
            {...rest} 
          />
      }
    </>
  )
}

export default TabPanel

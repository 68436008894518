import { create } from 'zustand'

import tagRepository from '@modules/Tag/Infrastructure/Repository/TagRepository'
import TagState from '@modules/Tag/Presentation/store/TagState'

export const useTagStore = create<TagState>((set) => {
  const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png']
  const IMAGE_SIZE_REG_EXP = /\.((jpg|png|jpeg))(?=\?|$)/i
  const IMAGE_PARAMS_REG_EXP = /\.(jpg|jpeg|png)(\?.*)$/
  const IMAGE_ID_REG_EXP = /v2\/([a-zA-Z0-9]+)(?=\.)/

  return {
    error: '',
    image: '',
    imageAuth: '',
    imageExt: '',
    imageId: '',
    loading: false,
    suggestedTags: [],
    tags: [],
    getImageFromNewsUrl: async (url: string) => {
      const news = await tagRepository.getInfoFromNewsUrl(url)

      if (!news.image) {
        set({ error: 'No se ha podido obtener la imagen de la noticia.' })
        return
      }

      const ext = news.image.match(IMAGE_SIZE_REG_EXP)
      if (!VALID_EXTENSIONS.includes(ext[1]) || null === ext) {
        set({ error: `La plataforma Urban Airship no acepta el formato de la imagen proporcionada por CAPI: .${ext[1]}` })
        return
      }

      const authMatch = news.image.match(IMAGE_PARAMS_REG_EXP)
      if (authMatch && authMatch[2]) {
        const params = authMatch[2].substring(1)
        const paramsArray = params.split('&')
        const authParam = paramsArray.find(param => param.startsWith('auth='))
        if (authParam) {
          const [key, value] = authParam.split('=')
          const authObj = { [key]: value }
          const idMatch = news.image.match(IMAGE_ID_REG_EXP)

          set({
            error: '',
            imageAuth: authObj.auth,
            imageExt: ext[1],
            imageId: idMatch[1]
          })
        }
      }
    },
    getSuggestedPepTags: async (term: string) => {
      set({ loading: true })
      const tags = await tagRepository.getSuggestedPepTags(term)
      set({ loading: false, suggestedTags: tags })
    },
    getSuggestedTagsFromNewsUrl: async (url: string) => {
      const news = await tagRepository.getInfoFromNewsUrl(url)
      set({ tags: news.tags })
    },
    getMetadataImage: async (id: string, businessUnit: string) => {
      set({ loading: true })

      const imageData = await tagRepository.getImageInfo(id, businessUnit)
      if (!imageData?.cdn.length) {
        set({ 
          error: 'No se ha podido obtener la imagen de PhotoCenter',
          loading: false
        })
        return
      }

      const ext = imageData.cdn.match(IMAGE_SIZE_REG_EXP)
      if (!ext) {
        set({ 
          error: 'La extensión de la imagen no es soportada por la plataforma Urban Airship',
          loading: false
        })
        return
      }

      set({
        error: '',
        imageAuth: imageData.auth[0], 
        imageExt: ext[1], 
        imageId: imageData.id
      })

      set({ loading: false })
    },
    setImage: (url: string) => {
      set({ image: url })
    },
    setTag: (tag: string) => {
      set((state) => ({
        tags: [ ...state.tags, tag ],
        suggestedTags: state.suggestedTags.filter(suggestedTag => suggestedTag !== tag)
      }))
    },
    removeTag: (tagToDelete: string) => {
      set((state) => ({
        tags: state.tags.filter(tag => tag !== tagToDelete),
        suggestedTags: [ ...state.suggestedTags, tagToDelete ]
      }))
    },
    resetTags: () => {
      set({ tags: [] })
    },
    resetImage: () => {
      set({ image: '' })
    }
  }
})
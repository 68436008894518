import { useEffect } from 'react'
import { FieldValues, UseFormSetValue } from 'react-hook-form'
import { Dimmer, Image, Loader, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react'

import useModal from '@shared/Presentation/hooks/useModal'
import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

import Button from '@shared/Presentation/components/common/Button'
import Modal from '@shared/Presentation/components/common/modal/Modal'
import PhotoCenterModal from '@shared/Presentation/components/common/modal/photocenter/PhotoCenterModal'
import ResizerModal from '@shared/Presentation/components/common/modal/resizer/ResizerModal'
import Text from '../../Text'
import toaster from '@shared/Infrastructure/Toaster/Toaster'

type TImageSelectorModal = {
  businessUnit: string
  isShowing: boolean
  newsUrl: string
  setValue: UseFormSetValue<FieldValues>
  toggleImageSelectorModal: () => void
}

const ImageSelectorModal: React.FC<TImageSelectorModal> = ({ businessUnit, newsUrl, isShowing, setValue, toggleImageSelectorModal }) => {
  
  const {
    openPhotoCenterModal,
    resizerModal,
    closePhotoCenterModal,
    closeResizerModal,
    openResizerModal,
    togglePhotoCenterModal
  } = useModal()

  const {
    error,
    image,
    imageAuth,
    loading,
    getImageFromNewsUrl,
    resetImage
  } = useTagStore(state => state)

  useEffect(() => {
    if (error.length) {
      toaster.error(error)
    }
  }, [error])

  const onSave = () => {
    setValue('image', image)
    toggleImageSelectorModal()
  }

  const onBack = () => {
    setValue('image', '')
    resetImage()
    toggleImageSelectorModal()
  }

  const onGetCapiImage = async () => {
    getImageFromNewsUrl(newsUrl)
    openResizerModal()
  }

  return (
    <Modal open={isShowing}>
      <Dimmer active={loading}>
        <Loader>Cargando...</Loader>
      </Dimmer>
      <ModalHeader>Cargar una Imagen para la Notificación</ModalHeader>
      <ModalContent>
        <p className='text-yellow-600'>* Formatos de imagen aceptados: .jpg | .jpeg | .png (Sensible a mayúsculas/minúsculas)</p>
        <div className='flex gap-2 items-center justify-center py-4'>
          <Button
            className='bg-slate-500 font-semibold text-white'
            disabled={!newsUrl.length}
            type='button'
            onClick={onGetCapiImage}
          >
            Cargar Imagen de CAPI
          </Button>
          <Button
            className='bg-slate-500 font-semibold text-white'
            type='button'
            onClick={togglePhotoCenterModal}
          >
            Cargar Imagen de PhotoCenter
          </Button>
        </div>
        <div className='flex items-center justify-center'>
          {
            !error.length && image.length
              ? <div className='border-2 border-slate-300 p-4'>
                <Image size='large' src={image} />
              </div>
              : <Text className='text-red-600 text-xl'>{error}</Text>
          }
        </div>
      </ModalContent>
      <ModalActions>
        <div className='flex gap-2 justify-end'>
          <Button
            className='bg-green-500 font-semibold text-white'
            disabled={!image.length}
            type='button'
            onClick={onSave}
          >
            Guardar
          </Button>
          <Button
            className='bg-blue-500 font-semibold text-white'
            type='button'
            onClick={onBack}
          >
            Volver
          </Button>
        </div>
      </ModalActions>
      {
        openPhotoCenterModal 
        && <PhotoCenterModal
          businessUnit={businessUnit}
          isShowing={openPhotoCenterModal}
          closePhotoCenterModal={closePhotoCenterModal}
          openResizerModal={openResizerModal}
        />
      }
      {
        resizerModal && imageAuth.length
        && <ResizerModal
          businessUnit={businessUnit}
          open={resizerModal}
          setValue={setValue}
          closeResizerModal={closeResizerModal}
        />
      }
    </Modal>
  )
}

export default ImageSelectorModal
import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import AzureLogin from './Azure'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

const azureLogin = new AzureLogin()

export const API: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
})

const Interceptor = ({ children }) => {
  const { instance } = useMsal()

  useEffect(() => {
    const requestInterceptor = (config: AxiosRequestConfig): any => {
      const token = sessionStorage.getItem('token')
      config.headers = { ...config.headers, 'Authorization': `Bearer ${token}` }
      return config
    }
 
    const responseInterceptor = (response: AxiosResponse): AxiosResponse => response.data

    const errInterceptor = async (error: AxiosError) => {
      const { response } = error
      const data: any = response?.data

      if (response) {
        if (response.status === 401 || data.code === 401) {
          if (error.config.url === '/login') {
            await azureLogin.azureRefreshToken(instance)
            window.location.href = '/'
            return
          } else {
            toaster.error('Su sesión ha caducado. Por favor, refresca la página o abre el editor en una nueva pestaña.')
          }
        }

        if (response.status === 403) {
          window.location.href = '/unauthorized'
          return
        }

        if (response.status === 503) {
          toaster.error('Ha habido un problema inesperado.')
        }
      }

      return Promise.reject(data)
    }

    const reqInterceptor = API.interceptors.request.use(requestInterceptor)
    const resInterceptor = API.interceptors.response.use(responseInterceptor, errInterceptor)

    return () => {
      API.interceptors.request.eject(reqInterceptor)
      API.interceptors.response.eject(resInterceptor)
    }
  }, [])

  return children
}

export default Interceptor